import { writable, derived } from "svelte/store";

export const zoomLevel = writable(0)
export const primaryScale = writable(1)
export const primaryScaleStart = writable()
export const primaryScaleEnd = writable()
// export const secondaryScale = writable(7)
export const primaryColumns = writable(Array(1))
export const secondaryColumns = writable(Array(1))
export const vocabMap = writable(new Map())
