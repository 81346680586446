<!--<svelte:options tag="gantt-chart" /> -->
<svelte:options accessors={true} />

<script>
  import GanttTable from "./components/GanttTable.svelte";
  import GanttNavi from "./components/GanttNavi.svelte";
  import { apiStoreGET } from "./api";
  import { startOfWeek, endOfWeek, getWeek, addWeeks } from "date-fns";
  import deDeLocale from "date-fns/locale/de";
  import { setContext } from 'svelte';
  import { getContext } from 'svelte';
  import { vocabMap } from "./stores";

  export let centerDate = new Date(Date.now());
  const objecturl = getContext("objecturl");

  let searchurl = new URL(objecturl);
  searchurl.search = new URLSearchParams("sort_on=start&sort_order=ascending&metadata_fields=start&metadata_fields=end&metadata_fields=Subject&metadata_fields=UID&metadata_fields=termin_art").toString();
  const searchurlString = searchurl.toString();
  let currentWeekStartDate;
  let currentWeekEndDate;
  let currentWeekNumber;
  let eventsResponse = apiStoreGET();

  let primaryBackgroundColor = "#B0E6CE";
  let primaryFontColor = "#27664A";
  let secondaryBackgroundColor = "#FFA963";
  let secondaryAlternativeBackgroundColor = "#FED8C3";
  let secondaryFontColor = "#4B2609";

  $: {
    currentWeekStartDate = startOfWeek(centerDate, {
      locale: deDeLocale,
    });
    currentWeekEndDate = endOfWeek(centerDate, {
      locale: deDeLocale,
    });
    currentWeekNumber = getWeek(centerDate, { locale: deDeLocale });

    // we need to search only for the time range here, when DateRangeInRange is fixed:
    console.log(searchurlString)
    eventsResponse = apiStoreGET(searchurlString);
  }

  function previous(e) {
    centerDate = addWeeks(centerDate, -1);
  }

  function now(e) {
    centerDate = addWeeks(new Date(Date.now()), 0);
  }

  function next(e) {
    centerDate = addWeeks(centerDate, 1);
  }

  setContext("previous", previous)
  setContext("next", next)
  setContext("now", now)
</script>

  {#await $eventsResponse}
    loading ...
  {:then data}
    <div class="Gantt">
      <div class="Week">
        <GanttNavi gantttitle={currentWeekNumber} />
        <GanttTable
          items={data.items}
          tableStartDate={currentWeekStartDate}
          tableEndDate={currentWeekEndDate}
          categoryField="termin_art"
        />
      </div>
    </div>
  {/await}

<style>
</style>
