<script>
  export let primaryHeaders;
</script>

<div class="headerWrapper">
  <div class="headerPrimaryColumnLabel">Terminart</div>
  <div
    class="headerPrimaryColumns"
    style="grid-template-columns: repeat({primaryHeaders.length}, 1fr);"
  >
    {#each primaryHeaders as col, i}
      <div class="headerPrimaryColumn gcol-{i}">{col}</div>
    {/each}
  </div>
</div>

<style>
  .headerWrapper{
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 8px solid var(--header-background-color);
  }
  .headerPrimaryColumns {
    display: grid;
    width: 100%;
    grid-auto-flow: row;
    grid-auto-flow: dense;
    grid-gap: 0 3px;
  }
  .headerPrimaryColumn {
    background-color: var(--header-background-color);
    color: var(--header-font-color);
    text-align: center;
    padding: 0.6em;
  }
  .headerPrimaryColumnLabel {
    width: 127px;
    flex-shrink: 0;
    padding: 0.6em;
    color: var(--header-font-color);
    border-right: 0 solid transparent;
    background-color: var(--header-background-color);
    margin-right: 3px;
  }
</style>
