import App from './App.svelte';

let targets = document.getElementsByTagName("gantt-chart");

for(let i = 0;i < targets.length; i++){
    let target = targets[i];
    const app_instance = new App({
    target: target,
    props: {
      currentobjecturl: target.getAttribute('currentobjecturl'),
      objecturl: target.getAttribute('objecturl'),
      navigationrooturl: target.getAttribute('navigationrooturl'),
    }
  });
}
