<script>
  import GanttItem from "./GanttItem.svelte";

  export let rowId;
  export let rowHeight;
  export let primaryScale;
  export let items;
  // export let rowColorFont;
  // export let rowColorBg;
  // export let rowItemColorFont;
  // export let rowItemColorBg;
  const label = rowId;
  rowId = rowId || "default";
</script>

<div class="GanttRowLabelWrapper">
  <div class="GanttRowLabel">
    {label}
  </div>
  <div
    class="GanttRow"
    class:rowId
    id="row_{rowId}"
    style="min-height: {rowHeight}px; grid-template-columns: repeat({primaryScale}, 1fr);"
  >
    <div class="GanttRowContent">
      {#each items as item, itemId}
        <div class="GanttItemWrapper">
          <GanttItem
            itemName={item.name}
            itemDescription={item.description}
            startDate={item.startDate}
            endDate={item.endDate}
            overflowLeft={item.overflowLeft}
            overflowRight={item.overflowRight}
            itemSpan={item.span}
            itemStart={item.start}
            itemUrl={item.url}
            itemUid={item.uid}
          />
        </div>
      {/each}
    </div>
  </div>
</div>

<style>
  .GanttRowLabelWrapper {
    display: flex;
    flex-wrap: nowrap;
    background-color: var(--row-color-bg);
    color: var(--row-color-font);
  }
  /* .GanttRowLabelWrapper:nth-child(odd){
    background-color: transparent;
  } */
  .GanttRowLabel {
    width: 130px;
    flex-shrink: 0;
    padding: 1em;
    box-sizing: border-box;
    border-right: 3px solid var(--header-background-color);
  }
  .GanttRow {
    display: grid;
    width: 100%;
    grid-auto-flow: row;
    grid-gap: 2px 3px;
    padding: 6px 0;
    /* border-bottom: 1px dotted #333; */
    margin-left: 2px;
  }
  .GanttRowContent {
    display: contents;
  }
  .GanttItemWrapper {
    position: relative;
    display: contents;
  }
</style>
