<script>
  import GanttRow from "./GanttRow.svelte";
  import GanttHeader from "./GanttHeader.svelte";
  // import { primaryScale } from "../stores.js";
  // import { primaryScaleStart, primaryScaleEnd } from "../stores.js";
  import { primaryColumns, vocabMap, zoomLevel } from "../stores.js";
  // import { secondaryColumns } from "../stores.js";
  // import GanttHeaderPrimaryScale from './GanttHeaderPrimaryScale.svelte';
  import {
    differenceInCalendarDays,
    getDayOfYear,
    parseISO,
    format,
  } from "date-fns";
  import deDeLocale from "date-fns/locale/de";
  import { getContext } from "svelte";
  import { get } from "svelte/store";
  import { apiGET, getVocabMap } from "../api";

  export let items = [];
  export let categoryField;
  export let tableStartDate;
  export let tableEndDate;
  export let zoomLevels;
  export let ganttMode = "week";
  const rooturl = getContext("rooturl");
  // const baseurl = getContext("baseurl");
  const objecturl = getContext("objecturl");
  let categoryvocaburl =
    rooturl + "/@vocabularies/collective.taxonomy.termin_art";
  let rowHeight = 30;
  let rowCategories = new Map();
  let primaryHeaders;

  zoomLevels = zoomLevels || {
    0: {
      primaryScale: 7,
      primaryHeaders: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
    },
  };
  primaryHeaders = zoomLevels[0].primaryHeaders;

  function calcStartSpanFromDates(start, end) {
    if(!start){
      return
    }
    if(!end){
      return
    }
    let itemStartDate = parseISO(start);
    let itemEndDate = parseISO(end);
    let itemStartInScale;
    let itemEndInScale;
    let itemStart;
    let itemSpan;
    let overflowLeft = false;
    let overflowRight = false;
    let itemDates;
    if (itemStartDate > tableEndDate || itemEndDate < tableStartDate) return;
    if (itemStartDate < tableStartDate) {
      itemStartInScale = tableStartDate;
      overflowLeft = true;
    } else {
      itemStartInScale = itemStartDate;
    }
    if (itemEndDate > tableEndDate) {
      itemEndInScale = tableEndDate;
      overflowRight = true;
    } else {
      itemEndInScale = itemEndDate;
    }
    if (ganttMode === "monthly") {
      itemStart = getDayOfYear(itemStartInScale);
    } else {
      itemStart = itemStartInScale.getDay();
    }
    itemSpan = differenceInCalendarDays(itemEndInScale, itemStartInScale) + 1;
    itemDates = {
      startDate: format(itemStartDate, "P", { locale: deDeLocale }),
      endDate: format(itemEndDate, "P", { locale: deDeLocale }),
      start: itemStart,
      span: itemSpan,
      overflowLeft: overflowLeft,
      overflowRight: overflowRight,
    };
    return itemDates;
  }

  class CatRow {
    constructor(
      rowColorBg,
      rowColorFont,
      rowItemColorBg,
      rowItemColorFont,
      items
    ) {
      this.rowColorBg = rowColorBg;
      this.rowColorFont = rowColorFont;
      this.rowItemColorBg = rowItemColorBg;
      this.rowItemColorFont = rowItemColorFont;
      this.items = items;
    }
  }

  async function buildRows(items) {
    let rowCategories = new Map();
    const vocab = await getVocabMap(categoryvocaburl);
    for (const item of items) {
      let rowItem;
      let catRow = new CatRow("transparent", "black", "lightblue", "black", []);
      let category = "";

      if (categoryField) {
        let categoryFieldValue = item[categoryField];
        if(!categoryFieldValue){
          categoryField = "taxonomy_" + categoryField
          let categoryFieldValue = item[categoryField];
        }
        if (categoryFieldValue) {
          category = categoryFieldValue;
          const categoryToken = item[categoryField];
          category = vocab.get(categoryToken);
          const currentCollection = await apiGET(objecturl);
          if (!currentCollection.color_mapping) {
            currentCollection.color_mapping = [
              {
                termin_art: "",
                row_color_fg: "transparent",
                row_color_font: "black",
                row_item_color_fg: "transparent",
                row_item_color_font: "black",
              },
            ];
          }
          for (const colorMap of await currentCollection.color_mapping) {
            const terminArt = await colorMap.termin_art;
            if (terminArt === categoryToken) {
              catRow.rowColorBg = colorMap.row_color_bg;
              catRow.rowColorFont = colorMap.row_color_font;
              catRow.rowItemColorBg = colorMap.row_item_color_bg;
              catRow.rowItemColorFont = colorMap.row_item_color_font;
            }
          }
          if (!category) {
            category = "";
          }
        }
      }

      if (rowCategories.has(category)) {
        catRow = rowCategories.get(category);
      } else {
        rowCategories.set(category, catRow);
      }
      let itemDates = calcStartSpanFromDates(item.start, item.end);
      if (!itemDates) continue;
      rowItem = {
        name: item.title,
        description: item.description,
        startDate: itemDates.startDate,
        endDate: itemDates.endDate,
        start: itemDates.start,
        span: itemDates.span,
        overflowLeft: itemDates.overflowLeft,
        overflowRight: itemDates.overflowRight,
        url: item["@id"],
        uid: item.UID,
      };
      catRow.items = [...catRow.items, rowItem];
      rowCategories.set(category, catRow);
    }
    return rowCategories;
  }

  $: (async () => (rowCategories = await buildRows(items)))();

  $: primaryScale = zoomLevels[$zoomLevel].primaryScale;
  $: primaryHeaders = zoomLevels[$zoomLevel].primaryHeaders;

  $: {
    $primaryColumns = Array.from(Array(primaryScale), (e, i) => {
      return { id: i + 1, name: primaryHeaders[i] };
    });
  }
</script>

<div class="GanttTable">
  <div id="popover-content" />
  <GanttHeader {primaryHeaders} />
  {#each [...rowCategories] as [catLabel, catRow]}
    <GanttRow
      rowId={catLabel}
      {rowHeight}
      {primaryScale}
      --row-color-font={catRow.rowColorFont}
      --row-color-bg={catRow.rowColorBg}
      --row-item-color-font={catRow.rowItemColorFont}
      --row-item-color-bg={catRow.rowItemColorBg}
      items={catRow.items}
    />
  {/each}
</div>

<style>
  .GanttTable {
    max-width: 100%;
  }
</style>
