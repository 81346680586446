<script>
  import GanttTable from "./components/GanttTable.svelte";
  import GanttNavi from "./components/GanttNavi.svelte";
  import { apiStoreGET } from "./api";
  import {
    startOfWeek,
    endOfWeek,
    getWeek,
    addWeeks,
    subWeeks,
  } from "date-fns";
  import { setContext } from "svelte";
  import { getContext } from 'svelte';
  import deDeLocale from "date-fns/locale/de";

  export let centerDate;
  const objecturl = getContext("objecturl");

  let searchurl = new URL(objecturl);
  searchurl.search = new URLSearchParams("sort_on=start&sort_order=ascending&metadata_fields=start&metadata_fields=end&metadata_fields=Subject&metadata_fields=UID&metadata_fields=termin_art").toString();
  const searchurlString = searchurl.toString();
  let currentWeekStartDate;
  let currentWeekEndDate;
  let currentWeekNumber;
  let previousWeekStartDate;
  let previousWeekEndDate;
  let previousWeekNumber;
  let nextWeekStartDate;
  let nextWeekEndDate;
  let nextWeekNumber;
  let eventsResponse = apiStoreGET(searchurlString);

  let primaryBackgroundColor = "#B0E6CE";
  let primaryFontColor = "#27664A";
  let secondaryBackgroundColor = "#FFA963";
  let secondaryAlternativeBackgroundColor = "#FED8C3";
  let secondaryFontColor = "#4B2609";

  $: {
    centerDate = centerDate || new Date(Date.now());

    ({
      startDate: currentWeekStartDate,
      endDate: currentWeekEndDate,
      weekNumber: currentWeekNumber,
    } = getDateInfos(centerDate));

    ({
      startDate: previousWeekStartDate,
      endDate: previousWeekEndDate,
      weekNumber: previousWeekNumber,
    } = getDateInfos(addWeeks(centerDate, -1)));

    ({
      startDate: nextWeekStartDate,
      endDate: nextWeekEndDate,
      weekNumber: nextWeekNumber,
    } = getDateInfos(addWeeks(centerDate, 1)));

    // we need to search only for the time range here, when DateRangeInRange is fixed:
    // use min/max query to find only relevant items ##TODO
    eventsResponse = apiStoreGET(searchurlString);
  }

  function getDateInfos(centerDate) {
    return {
      startDate: startOfWeek(centerDate, {
        locale: deDeLocale,
      }),
      endDate: endOfWeek(centerDate, {
        locale: deDeLocale,
      }),
      weekNumber: getWeek(centerDate, { locale: deDeLocale }),
    };
  }

  function previous(e) {
    centerDate = addWeeks(centerDate, -1);
  }

  function now(e) {
    centerDate = addWeeks(new Date(Date.now()), 0);
  }

  function next(e) {
    centerDate = addWeeks(centerDate, 1);
  }

  setContext("previous", previous);
  setContext("next", next);
  setContext("now", now);
</script>

<!--<svelte:options tag="gantt-chart" /> -->

<div class="svelte-app-wrapper">
  <div class="Gantt">
    {#await $eventsResponse}
      loading ...
    {:then data}
    <div class="week-header">
      <h2 class="week-name">{previousWeekNumber}</h2>
      <h2 class="week-name">{currentWeekNumber}</h2>
      <h2 class="week-name">{nextWeekNumber}</h2>
      <div class="navi-wrapper"><GanttNavi /></div>
    </div>
      <div class="Weeks">
        <div class="Week">
          <GanttTable
            items={data.items}
            tableStartDate={previousWeekStartDate}
            tableEndDate={previousWeekEndDate}
            categoryField="termin_art"
          />
        </div>
        <div class="Week">
          <GanttTable
            items={data.items}
            tableStartDate={currentWeekStartDate}
            tableEndDate={currentWeekEndDate}
            categoryField="termin_art"
          />
        </div>
        <div class="Week">
          <GanttTable
            items={data.items}
            tableStartDate={nextWeekStartDate}
            tableEndDate={nextWeekEndDate}
            categoryField="termin_art"
          />
        </div>
      </div>
    {/await}
  </div>
</div>

<style>
  .Weeks {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: auto;
    grid-gap: 10px 10px;
  }
  h2 {
    color: #27664a;
    text-align: center;
    font-size: 2em;
    font-weight: 100;
    flex-grow: 1;
  }
  .week-header{
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
  }
  .navi-wrapper{
    width: 150px;
  }

  /* .svelte-app-wrapper {
    text-align: center;
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }


  @media (min-width: 640px) {
    .svelte-app-wrapper {
      max-width: none;
    }
  } */
</style>
